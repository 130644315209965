import React from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"
import Dialog from "@material-ui/core/Dialog"
import { Button, Grid } from "@material-ui/core"
import ReactMarkdown from "react-markdown"
import FormatQuoteRoundedIcon from "@material-ui/icons/FormatQuoteRounded"

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    root: {
      margin: "1rem",
    },
    name: {
      margin: 0,
    },
    review: {
      fontSize: "0.9em",
      margin: 0,
    },
    quote: {
      marginTop: "1rem",
    },
    avatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      backgroundColor: theme.palette.secondary.main,
    },
    separator: {
      margin: 50,
    },
  })
)

export interface TestimonialsDialogProps {
  open: boolean
  content: any
  selectedTestimonial: string
  onClose: () => void
}

export function TestimonialsDialog(props: TestimonialsDialogProps) {
  const classes = useStyles()
  const { onClose, selectedTestimonial, content, open } = props

  const handleClose = () => {
    onClose()
  }

  var testimonial = content.find(item => item["id"] === selectedTestimonial)

  var { name, review, avatar } = testimonial

  return (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={handleClose}
    >
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid xs={4} container item justify="center" alignItems="center">
            <Avatar
              src={avatar.childImageSharp.fluid.srcWebp}
              className={classes.avatar}
              imgProps={{ style: { margin: 0 } }}
              alt={name}
            />
          </Grid>
          <Grid xs={4} container item justify="center" alignItems="center">
            <h5 className={classes.name}>{name}</h5>
          </Grid>
        </Grid>
        <div className={classes.quote}>
          <FormatQuoteRoundedIcon />
          <ReactMarkdown className={classes.review}>
            {review.split(" ").splice(0, 80).join(" ") + "..."}
          </ReactMarkdown>
          <FormatQuoteRoundedIcon style={{ float: "right" }} />
          <div className={classes.separator} />
        </div>
      </div>
    </Dialog>
  )
}
