import React from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import { Avatar, Button, Divider, Grid, Paper } from "@material-ui/core"
import ReactMarkdown from "react-markdown"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import { Hero } from "../components/hero/hero"
import { TourGallery } from "../components/tourgallery/tourgallery"
import { Feature } from "../components/feature/feature"
import { Testimonials } from "../components/testimonials/testimonials"

export const data = graphql`
  query {
    strapiHomepage {
      title
      about
      description
      aboutpic {
        childImageSharp {
          fluid(quality: 90, maxWidth: 220) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hero {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      features {
        description
        title
        icon {
          childImageSharp {
            fluid(quality: 90, maxWidth: 120) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      testimonials {
        id
        name
        review
        avatar {
          childImageSharp {
            fluid(quality: 90, maxWidth: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allStrapiTours {
      edges {
        node {
          summary
          slug
          title
          featured
          thumbnailoffset
          datelist {
            selling
          }
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 960) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    content: {
      maxWidth: "960px",
      marginLeft: "auto",
      marginRight: "auto",
      padding: "1rem",
    },
    aboutavatar: {
      width: "200px",
      height: "200px",
      marginTop: "-8rem",
      marginLeft: "auto",
      marginRight: "auto",
      overflow: "hidden",
      borderRadius: "50%",
    },
    about: {
      backgroundColor: theme.palette.primary.main,
      paddingTop: "4rem",
      marginTop: "5rem",
      paddingBottom: "2rem",
      width: "100%",
      color: theme.palette.secondary.main,
    },
    gallery: {
      backgroundColor: "transparent",
      width: "100%",
    },
    features: {
      maxWidth: "960px",
      margin: "1rem",
      marginLeft: "auto",
      marginRight: "auto",
      padding: "1rem",
    },
    feature: {
      minWidth: "250px",
    },
    reviews: {
      padding: "1rem",
      maxWidth: "960px",
      marginLeft: "auto",
      marginRight: "auto",
    },
    title: {
      textAlign: "center",
      marginTop: "4rem",
    },
  })
)

function IndexPage({ data }) {
  const classes = useStyles()

  return (
    <div>
      <SEO title="Home" image={data.strapiHomepage.hero.childImageSharp.fluid.src} />
      <Hero backgroundimage={data.strapiHomepage.hero.childImageSharp.fluid}>
        <React.Fragment>{data.strapiHomepage.description}</React.Fragment>
      </Hero>
      <Layout fullpage={true}>
        <div className={classes.gallery}>
          <div className={classes.content}>
            <TourGallery>
              {data.allStrapiTours.edges.filter(
                edge => edge.node.featured == true
              )}
            </TourGallery>
            {/* <Link
              className={classes.link}
              href="https://www.instagram.com/where.again/"
              color="inherit"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                className={classes.button}
                size="large"
                variant="outlined"
              >
                More
              </Button>
            </Link> */}
          </div>
        </div>
        <div className={classes.about}>
          <Paper elevation={3} className={classes.aboutavatar}>
            <Img
              fluid={data.strapiHomepage.aboutpic.childImageSharp.fluid}
              alt="Olesia"
            />
          </Paper>
          <ReactMarkdown
            className={classes.content}
            source={data.strapiHomepage.about}
          />
        </div>
        <div className={classes.features}>
          <Grid container spacing={2} alignContent="center" justify="center">
            {data.strapiHomepage.features.map(
              ({ icon, title, description }, index) => (
                <Grid key={index} item xs className={classes.feature}>
                  <Feature
                    thumbnail={icon.childImageSharp.fluid}
                    title={title}
                    description={description}
                  />
                </Grid>
              )
            )}
          </Grid>
        </div>
        <h1 className={classes.title}>Відгуки</h1>
        <Divider variant="middle" />
        <div className={classes.reviews}>
          <Testimonials>{data.strapiHomepage.testimonials}</Testimonials>
        </div>
      </Layout>
    </div>
  )
}
export default IndexPage
