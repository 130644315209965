import React from "react"

import Carousel from "react-multi-carousel" //https://www.npmjs.com/package/react-multi-carousel
import "react-multi-carousel/lib/styles.css"

import { Avatar, Button, Grid, Paper } from "@material-ui/core"
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles"
import FormatQuoteRoundedIcon from "@material-ui/icons/FormatQuoteRounded"
import ReactMarkdown from "react-markdown"
import { TestimonialsDialog } from "../testimonials-dialog/testimonials-dialog"

export interface TestimonialsProps {
  children: any
}

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    testimonial: {
      padding: "1rem",
      margin: "0.5rem",
      borderRadius: "12px",
      height: "100%",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.secondary.main,
      "& a": {
        color: theme.palette.secondary.main,
      },
      "& a:hover": {
        textDecoration: "underline",
      },
    },
    testimonials: {
      maxWidth: "960px",
      marginLeft: "auto",
      marginRight: "auto",
      paddingBottom: "3rem",
      "& button": {
        backgroundColor: theme.palette.primary.main,
        bottom: 0,
      },
      "& button:before": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      },
      "& button:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.main,
      },
    },
    testimonialsdots: {
      "& li > button": {
        marginRight: "0.6vw",
        width: "3vw",
        height: "3vw",
        maxWidth: "14px",
        maxHeight: "14px",
      },
    },
    avatar: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      backgroundColor: theme.palette.secondary.main,
    },
    name: {
      margin: 0,
    },
    review: {
      fontSize: "0.9em",
      margin: 0,
    },
    quote: {
      marginTop: "1rem",
    },
    separator: {
      margin: 50,
    },
    morebutton: {
      position: "fixed",
      bottom: 0,
      marginBottom: 10,
    },
  })
)

export const Testimonials: React.FC<TestimonialsProps> = ({ children }) => {
  const classes = useStyles()

  const [clickedTestimonial, setClickedTestimonial] = React.useState(
    children[0]["id"]
  )

  const [openTestimonial, setOpenTestimonial] = React.useState(false)
  const handleClickOpenTestimonial = id => {
    setClickedTestimonial(id)
    setOpenTestimonial(true)
  }
  const handleCloseTestimonial = () => {
    setOpenTestimonial(false)
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 650 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 650, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }

  return (
    <div>
      <TestimonialsDialog
        selectedTestimonial={clickedTestimonial}
        content={children}
        open={openTestimonial}
        onClose={handleCloseTestimonial}
      />
      <Carousel
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={4000}
        ssr={true}
        keyBoardControl={true}
        renderButtonGroupOutside={true}
        showDots={true}
        className={classes.testimonials}
        dotListClass={classes.testimonialsdots}
      >
        {children.map(({ id, name, review, avatar }, i) => (
          <Paper key={i} elevation={2} className={classes.testimonial}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid xs={4} container item justify="center" alignItems="center">
                <Avatar
                  src={avatar.childImageSharp.fluid.srcWebp}
                  className={classes.avatar}
                  imgProps={{ style: { margin: 0 } }}
                  alt={name}
                />
              </Grid>
              <Grid xs={4} container item justify="center" alignItems="center">
                <h5 className={classes.name}>{name}</h5>
              </Grid>
            </Grid>
            <div className={classes.quote}>
              <FormatQuoteRoundedIcon />
              <ReactMarkdown className={classes.review}>
                {review.split(" ").splice(0, 50).join(" ") + "..."}
              </ReactMarkdown>
              <FormatQuoteRoundedIcon style={{ float: "right" }} />
              <div className={classes.separator} />
              <Button
                variant="outlined"
                color="secondary"
                className={classes.morebutton}
                onClick={() => handleClickOpenTestimonial(id)}
              >
                Більше
              </Button>
              <div className={classes.separator} />
            </div>
          </Paper>
        ))}
      </Carousel>
    </div>
  )
}
